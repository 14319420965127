import './App.css';
import Input from 'components/Input'
import { useEffect, useRef, useState } from 'react';
import {AiFillFacebook} from "react-icons/ai";

function App() {
  const ref = useRef()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  useEffect(() => {
    let images = ref.current.querySelectorAll('img')
    let imagesCount = images.length
    let currentIndex = 0

    const changeImageInSlider = () => {
      images[(currentIndex > 0  ? currentIndex : imagesCount) - 1].classList.add('opacity-0')
      images[currentIndex].classList.remove('opacity-0')
      currentIndex = currentIndex === imagesCount - 1 ? 0 : currentIndex + 1
    }

    changeImageInSlider()
    const interval = setInterval(changeImageInSlider, 2000)

    return () => {
      clearInterval(interval)
    }

  }, [ref])

  const enabled = username && password


  return (
    <div className='h-full w-full flex flex-wrap overflow-auto gap-x-8 items-center justify-center'>
      <div className='w-[380px] h-[581px] bg-logo-pattern bg-[length:468.32px_634.15px] bg-[top_left_-46px] relative hidden md:block'>
        <div 
          className='w-[250px] h-[538px] absolute top-[27px] right-[18px]'
          ref={ref}
        >
          <img 
            className='w-full h-full absolute top-0 left-0 opacity-0 transition-opacity duration-1000 ease-linear'
            src='https://www.instagram.com/static/images/homepage/screenshots/screenshot1-2x.png/cfd999368de3.png'
            alt=''
          />
          <img 
            className='w-full h-full absolute top-0 left-0 opacity-0 transition-opacity duration-1000 ease-linear'
            src='https://www.instagram.com/static/images/homepage/screenshots/screenshot2-2x.png/80b8aebdea57.png'
            alt=''
          />
          <img 
            className='w-full h-full absolute top-0 left-0 opacity-0 transition-opacity duration-1000 ease-linear'
            src='https://www.instagram.com/static/images/homepage/screenshots/screenshot3-2x.png/fe2540684ab2.png'
            alt=''
          />
          <img 
            className='w-full h-full absolute top-0 left-0 opacity-0 transition-opacity duration-1000 ease-linear'
            src='https://www.instagram.com/static/images/homepage/screenshots/screenshot4-2x.png/8e9224a71939.png'
            alt=''
          />
        </div>
      </div>

      <div className='w-[350px] grid gap-y-3'>
        <div className='bg-white border px-[40px] pt-10 pb-6'>
          <a href="#" className="flex justify-center mb-8">
						<img className="h-[51px]" src="https://www.instagram.com/static/images/web/logged_out_wordmark-2x.png/d2529dbef8ed.png" alt=""/>
          </a>
          <form className='grid gap-y-1.5'>
            <Input type="text" value={username} onChange={e => setUsername(e.target.value)} label="Phone number, username or email"></Input>
            <Input type="password" value={password} onChange={e => setPassword(e.target.value)} label="Password"></Input>
            <button 
              type="submit" 
              disabled={!enabled} 
              className="h-[30px] mt-1 rounded-md bg-brand font-medium text-white text-sm disabled:opacity-70"
            >
              Log In
            </button>
            <div className="flex items-center my-2.5 mb-3.5">
							<div className="h-px bg-gray-300 flex-1"/>
							<span className="px-4 text-[13px] text-gray-500 font-semibold">OR</span>
							<div className="h-px bg-gray-300 flex-1"/>
						</div>
						<a href="#" className="flex justify-center mb-2.5 items-center gap-x-2 text-sm font-semibold text-facebook">
							<AiFillFacebook size={20}/>
							Log in with Facebook
						</a>
						<a href="#" className="text-xs flex items-center justify-center text-link">
							Forgot password?
						</a>
          </form>
        </div>
        <div className='bg-white border p-4 text-sm text-center'>
          Don't have an account? <a href="#" className="font-semibold text-brand">Sign up</a>
        </div>
      </div>
    </div>
  );
}

export default App;
